<template lang='pug'>
.font-16.text-primary.text-weight-bold.q-my-md.q-pl-md
    | Список товаров по алфавиту
q-scroll-area.full-width.banner(
    :thumb-style="{display: 'none'}"
    style='height: 50px'
    )
    .row.no-wrap.q-pt-sm.q-pl-sm
            q-list.row.no-wrap
            q-item.top.cursor-pointer.no-wrap(
                v-for='(letter,index) of alphabet'
                :key='index'
                :class="{'activeLetter': activeLetter === letter}"
                @click="$emit('checkActiveLetter',letter)"
            )
                q-item-section.text-primary.font-20.text-weight-medium(
                    @click="$emit('checkActiveLetter',letter)"
                )
                    | {{letter}}
q-spinner.absolute-center(
    v-if='!filteredList.length && $route.params.letter'
    color="positive"
    size="5em"
    thickness='4'
)
q-list.products.row.col-12(v-if='filteredList.length' )
    q-item.col-6(
        v-for='(item,index) of filteredList'
        :key='index'
    )
        q-item-section.product.font-14
            router-link.text-primary(
                :to="`/search?query=${item.name}&limit=20`"
                style='text-decoration: none'
            )
                | {{item.name}}
//q-btn(
//    v-if="currentPage < totalPages"
//    @click="nextPage(currentPage)")
//  |Показать еще
</template>

<script>
import HeaderMobile from 'components/Header/HeaderMobile.vue';

export default {
    name: 'alphabetDesktop',
    components: { HeaderMobile },
    props: {
        alphabet: {
            type: Array,
        },
        activeLetter: {
            type: String,
        },
        filteredList: {
            type: Array,
        },
        currentPage:{
            type:Number,
        },
        totalPages:{
            type: Number,
        },
    },
    emits: ['checkActiveLetter','changePage'],

    setup(props,{ emit }){
        const nextPage = (page)=>{
            let newPage = page;
            emit('changePage',newPage++);
        };
        return{
            nextPage,
        };
    },
};
</script>

<style scoped lang='scss'>
.q-item.top {
    padding: 0 10px;
    height: min-content;
    min-height: 5px;
    width: max-content;

    &:hover,&:target,&:active{
        border-radius: 10px;
        background: #FFFFFF;
        color: #0CA551 !important;
        outline: 1px solid #0CA551;
    }

    &.activeLetter {
        border-radius: 10px;
        background: #0CA551;
        color: #FFFFFF !important;
    }
}

.q-list {
    min-height: min-content;
}

.activeLetter {
    border-radius: 10px;
    background: #0CA551;
    color: #FFFFFF !important;
}
</style>
